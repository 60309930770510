import { useEffect, useState } from "react";
import { Progress } from "antd";
import { getRadialColor } from "../../Utils/CommonFunctions";
import { useSearchParams } from 'react-router-dom'
import './index.scss'
import { handleProductCardClick } from "../../Utils/Gtag";

const ProductCard = ({ product, domain }) => {
  const [searchParams] = useSearchParams();
  const [productName, setProductName] = useState("")
  const [gs, setGs] = useState(0)
  const [productUrl, setProductUrl] = useState("")
  const [img, setImg] = useState("")

  const client = searchParams.get("client")
  const temp = searchParams.get("temp")

  useEffect(() => {
    if (!client) {
      setProductName(product?.productName)
      setGs(product?.totalGs)
      setProductUrl(`https://qrdemo.greenchoice.co/p/${product?.productName.split(" ").join("-")}`)
      if (product?.productImage.includes("https://greenchoice.gumlet.com/")) {
        setImg("./images/placeholder.png")
      } else {
        setImg(product?.productImage)
      }
    } else {
       setProductName(product["Retailer Product Name"] || "");
       setGs(product?.totalGs || "")
       if (product?.mainProductImage.includes("https://greenchoice.gumlet.com/")) {
          setImg("./images/placeholder.png")
        } else {
          setImg(product?.mainProductImage || "")
        }
       if (client === "mfc") {
          setProductUrl(`https://marquette-food-coop.myshopify.com/products/${product?.productNameURL}`)
       }
       if (client === "orcasprovisions" && temp === "true") {
        setProductUrl(`https://provisions-mainmarket.myshopify.com/collections/unfi/products/${product["Retailer Primary Key"]}`)
        setGs(product?.totalGs)
      } else if (domain) {
        const base_url = domain.split("/products/")[0] + "/products/";
        setProductUrl(`${base_url}${product["Retailer Primary Key"]}`)
      } 
    }
  }, [])

  return (
    <a target="_PARENT" href={productUrl}>
      <div
        className="product-card card"
        onClick={() => handleProductCardClick(productName)}
      >
        <Progress
          type="circle"
          percent={Math.round(gs)}
          size={36}
          className="fw-bold text-primary pt-1"
          strokeWidth="10"
          strokeLinecap=""
          format={(percent) => percent + ""}
          strokeColor={`${getRadialColor(Math.round(gs))}`}
        />
        <img loading="lazy" className="product-img" src={img} alt={productName} />
        <p className="product-title">{productName}</p>
      </div>
    </a>
  );
};

export default ProductCard;
