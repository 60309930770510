import React from 'react'
import { useSearchParams } from "react-router-dom";
import { Spin } from 'antd';
import ProductImage from "../../Assets/Images/pepsi.webp"
import './style.css'

const ProductDemo = () => {
  const [searchParams] = useSearchParams();
  const size = searchParams.get("radialSize");
  const upcCode = searchParams.get("upcCode");
  const label = searchParams.get("label")

  return (
    <>
        <header className='bg-success'>
          <div class="container">
            <nav class="navbar navbar-expand-md navbar-dark">
              <a class="navbar-brand" href="#"> Logo </a>
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav ms-auto px-2">
                  <li class="nav-item">
                    <a class="nav-link" href="#">Home </a>
                  </li>
                  <li class="nav-item px-2">
                    <a class="nav-link" href="#">About</a>
                  </li>
                  <li class="nav-item active px-2">
                    <a class="nav-link" href="#">Products</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>

        <main class="my-md-5 my-3" id="product-demo">
            <div class="container">
                <div class="row my-2 align-items-start">
                <div class="col-lg-6">
                    <div class="main-img">
                    <img
                        src={ProductImage}
                        alt="ProductS"
                    />
                    </div>
                </div>
                <div class="col-lg-6 mb-md-4 mb-0">
                    <div class="main-description">
                    <div
                        class="product-title fw-bold my-1 d-flex align-items-center"
                    >
                        <h3 class="text-capitalize"> Pepsi Black Soft Drink - Zero Sugar </h3>
                         <iframe title="gs-radial" src={`https://widgets.gcpbc.co/getRadial?upcCode=${upcCode}&radialSize=${size}&label=${label}`} id="gs-radial" frameBorder="0" loading="eager"></iframe>
                    </div>

                    <div class="price-area">
                        <h3 class="new-price fw-bol mb-1">$23</h3>
                    </div>

                    <div class="buttons d-flex align-items-center my-4">
                        <div class="block">
                        <a href="#" class="shadow btn my-2  px-4 py-2 btn-primary">Wishlist</a>
                        </div>
                        <div class="block">
                        <button class="shadow btn m-2 px-4 py-2 btn-primary">Add to cart</button>
                        </div>

                        <div class="block quantity">
                        <input
                            type="number"
                            class="form-control"
                            id="cart_quantity"
                            value="1"
                            min="0"
                            max="5"
                            placeholder="Enter email"
                            name="cart_quantity"
                        />
                        </div>
                    </div>
                    </div>

                    <div class="product-details my-5">
                    <h5 class="details-title text-color mb-1">Product Details</h5>
                    <p id="description" class="description my-2 text-capitalize">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corrupti non ab ratione expedita nemo exercitationem!
                    </p>
                    </div>
                </div>
                </div>

                 <div className="mt-5 chart-wrap">
                  <iframe
                  title="gs-chart" src={`https://widgets.gcpbc.co/getChart?upcCode=${upcCode}`} id="gs-chart" frameBorder="0"  loading="eager"></iframe>
                  
                  </div>

                 <iframe title="bfy-carousel" src={`https://widgets.gcpbc.co/getBfy?upcCode=${upcCode}`} id="bfy-carousel" frameBorder="0" loading="eager"></iframe>
            </div>
        </main>
    
        <footer class="footer bg-success text-center p-3">
          <div class="container">
            <span class="text-white p-1"> &copy; 2024, Inc. All rights reserverd. </span>
          </div>
        </footer>
    </>
  )
}

export default ProductDemo